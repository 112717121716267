import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'

const Informacion = () => {
    return(
        <div id="informacion" className="section section-sections section-dark">
            <Container>
                <Col className="ml-auto mr-auto" md="8">
                    <div className="section-description text-center">
                    <h2 className="title">Información</h2>
                    <h5 className="description">
                        En 80 hectáreas ubicadas al oriente del camino que une Cachagua y Zapallar, 
                        se emplaza Fundo Zapallar. Como una verdadera platea frente al mar, 
                        de vista majestuosa, Fundo Zapallar tiene las características de los 
                        mejores lugares del mundo, barrio caminable de origen memorable, 
                        lleno de encanto y cautivantes detalles.
                    </h5>
                    <br />
                    <Button
                        className="btn-rose btn-round"
                        color="default"
                        to="/#paisajismo"
                        tag={Link}
                    >
                        Ver más
                    </Button>
                    </div>
                </Col>
            </Container>
        </div>
    )
}
const Paisajismo = () => {
    return(
        <div id="paisajismo" className="section section-sections section-gray">
            <Container>
                <Col className="ml-auto mr-auto" md="8">
                    <div className="section-description text-center">
                        <h2 className="title">Paisajismo</h2>
                        <h5 className="description">
                            Rescatando la tradicional arquitectura que caracteriza a Zapallar, 
                            Fundo Zapallar aprovecha un fuerte énfasis en el paisajismo público y 
                            privado con quebradas y trazados serpenteantes en sus calles y paseos, 
                            tejidos por amables caminos, pequeñas plazas románticas, senderos, 
                            pircas y jardines temáticos, al más puro estilo de la Riviera Italiana.
                        </h5>
                        <br/>
                    </div>
                </Col>
            </Container>
        </div>
    )
}

const Atributos = () => {
    return(
    <>
        <div id="atributos"
          className="features-5 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/sections/atributos.jpg") +
              ")"
          }}
        >
            <Container>
                <Row>
                    <div className="ml-auto mr-auto">
                        <h2 className="title text-center">
                        Atributos
                        </h2>
                    </div>
                </Row>
                <Row>
                    <Col className="ml-auto" sm="5">
                        <div className="info">
                            <div className="icon">
                                <i aria-hidden={true} className="nc-icon nc-map-pin"/>
                            </div>
                            <h4 className="title">Ubicación</h4>
                            <p>
                                Fundo Zapallar se ubica en la mejor zona entre Cachagua y Zapallar en sector El Pangue, 
                                al oriente de la carretera, caminable en ambos sentidos.
                            </p>
                        </div>
                    </Col>
                    <Col className="mr-auto" sm="5">
                        <div className="info">
                            <div className="icon">
                                <i aria-hidden={true} className="nc-icon nc-road-2"/>
                            </div>
                            <h4 className="title">Urbanización</h4>
                            <p>
                                Urbanización de lujo, con calles y paisajismo singular que le da mucho carácter al proyecto, 
                                destacándose sus muros de piedras y tranqueras entre jardines de proteas de diferentes formas 
                                y colores.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="bottom-line">
                    <Col className="ml-auto" sm="5">
                        <div className="info">
                            <div className="icon">
                                <i aria-hidden={true} className="nc-icon nc-drop" />
                            </div>
                            <h4 className="title">Servicios</h4>
                            <p>
                                Cuenta con todos los servicios básicos propios de una ciudad, Agua Potable y Alcantarillado, Luz Eléctrica Subterránea, Gas Licuado y Seguridad.
                            </p>
                        </div>
                    </Col>
                    <Col className="mr-auto" sm="5">
                        <div className="info">
                            <div className="icon">
                                <i aria-hidden={true} className="nc-icon nc-currency-dollar" />
                            </div>
                            <h4 className="title">Plusvalía</h4>
                            <p>
                                Plusvalía probada en sus doce años de existencia, en donde la demanda ha incidido en un aumento de 
                                casi un 100% en los precios por m2. El nivel de construcciones lo posicionan como uno de los barrios 
                                más elegantes del borde costero.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
       
    )
}

const ElProyecto = () => {
    return(
        <>
            <Informacion/>
            <Paisajismo/>
            <Atributos />
        </>
    )
}
export default ElProyecto