import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import React, { Component } from 'react'
const APIKEY = 'AIzaSyBQSDucBmiqgSVMLKoQjrPeC4hVxGDlp2U'

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
          stores: [ {lat:-32.569796, lng:-71.450710},
                    {latitude:-32.569796, longitude:-71.450710} ]
        }
      }

    displayMarkers = () => {
        const { stores } = this.state
        return stores.map((store, index) => {
          return <Marker key={index} id={index} position={{
           lat: store.latitude,
           lng: store.longitude
         }}
         onClick={() => console.log("You clicked me!")} />
        })
    }
    render(){
        const style = {
            width: '100%',
            height: '100%',
            position:'relative'
          }
        return(
            <Map style={style} className="map" google={this.props.google} zoom={14} initialCenter={{lat:-32.569796, lng:-71.450710}}>
                {this.displayMarkers()}
            </Map>
        )
    }
}
const LoadingContainer = () => (
  <div className="uil-reload-css mr-1">
      <div />
  </div>
)
export default GoogleApiWrapper({
    apiKey: APIKEY,
    LoadingContainer: LoadingContainer
  })(MapContainer)
